import React from 'react'
import PropTypes from 'prop-types'

import ResetPassword from '../../components/ResetPassword'
import { getParamFromURL } from '../../helpers'
import SEO from '../../components/SEO'

const ResetPasswordPage = ({ location }) => {
  const token = getParamFromURL(location.search, 'token')
  return (
    <>
      <SEO page="resetPassword" />
      <ResetPassword token={token} />
    </>
  )
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ResetPasswordPage
