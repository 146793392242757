import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import { useEffect } from 'react'
import InputBox from '../Form/InputBox'
import Wrapper from '../Wrapper'
import CustomAlert from '../Alerts'

import { resetPasswordValidation } from '../../validations'
import { DOWNLOADER, LOGIN, HOME } from '../../data/constants'

import { postData } from '../../reducers/app'
import { UserContext } from '../UserContext'
import useForm from '../../hooks/useForm'

import { RESETPASSWORD } from '../../data/apis'

const init = {
  password: '',
  confirmPassword: '',
  captcha: false,
}

const ResetPassword = ({ token }) => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useContext(UserContext)
  const { values, errors, handleOnChange, handleSubmit, resetForm } = useForm(
    init,
    resetPasswordValidation,
    val => {
      dispatch(
        postData(RESETPASSWORD, {
          ...val,
          token,
        })
      )
    }
  )
  const {
    resetpass: { loading, loaded, error, data },
  } = useSelector(state => state.app)

  useEffect(() => {
    if (isLoggedIn) navigate(DOWNLOADER)
    if (data && data.msg) resetForm()
  }, [isLoggedIn, data])

  useEffect(() => {
    if (!token) navigate(HOME)
  }, [token])

  return (
    <Wrapper title="Reset Password">
      <div className="col-sm-6">
        <div className="cboxEraForm">
          {data && !data.msg && (
            <form onSubmit={handleSubmit}>
              <InputBox
                name="password"
                label="Password"
                type="password"
                placeholder="Password"
                value={values.password}
                onChangeInput={handleOnChange}
                error={errors.password}
              />
              <InputBox
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                placeholder="Re-enter Password"
                value={values.confirmPassword}
                onChangeInput={handleOnChange}
                error={errors.confirmPassword}
              />
              <div className="clear-fix mb-3" />
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <Button type="submit" color="success" disabled={loading}>
                    {loading ? 'Updating...' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          )}
          {loaded && !loading && (
            <CustomAlert type={`${data && data.msg ? 'success' : 'danger'}`}>
              <span>
                {data && data.msg ? (
                  <span>
                    Success ! Your Password has been reset.
                    <br /> You can{' '}
                    <Link className="text-danger" to={LOGIN}>
                      Login now!
                    </Link>
                  </span>
                ) : (
                  <span>{error}</span>
                )}
              </span>
            </CustomAlert>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

ResetPassword.defaultProps = {
  token: '',
}

ResetPassword.propTypes = {
  token: PropTypes.string,
}

export default ResetPassword
